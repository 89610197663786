<template>
  <section class="fontStandard">
    <!-- User Interface controls -->
    <b-row class="pt-5">
      <b-col md="4" lg="4" class="text-left">
        <h3>
          <b-badge variant="light"
            >{{ totalRows }} entrée{{ totalRows > 1 ? "s" : "" }}
          </b-badge>
        </h3>
      </b-col>
      <b-col md="0" lg="4"></b-col>
      <b-col md="6" lg="4">
        <b-input-group>
          <b-button @click="$refs.table.refresh()" variant="primary">
            <i class="fas fa-sync"></i>
          </b-button>
          <b-form-input
            v-model="filter"
            type="search"
            id="filterInput"
            placeholder="Recherche"
          ></b-form-input>
        </b-input-group>
      </b-col>
    </b-row>

    <b-table
      show-empty
      small
      responsive
      stacked="lg"
      ref="table"
      :items="myProvider"
      :fields="fields"
      :current-page="currentPage"
      :per-page="perPage"
      :filter="filter"
      :filterIncludedFields="filterOn"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :sort-direction="sortDirection"
      striped
      hover
      foot-clone
      bordered
      borderless
      head-variant="dark"
      table-variant="light"
    >
      <template v-slot:table-busy>
        <div class="text-center text-info my-2">
          <b-spinner class="align-middle"></b-spinner>
          <strong>Chargement...</strong>
        </div>
      </template>

      <template v-slot:cell(access_date)="data">
        <b>{{ data.value }}</b>
      </template>

      <template v-slot:cell(city)="data">
        <b v-b-popover.hover.right="data.item['country_full']">
          ({{ data.item["country"] }})
          {{ data.item["postal_code"] }}
          {{ data.item["city"] }}
        </b>
      </template>

      <template v-slot:cell(user_agent)="data">
        <b>{{ $tools.showLimitedString(data.value, truncateUrl) }} </b>
      </template>
    </b-table>

    <b-row>
      <b-col cols="8">
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          align="left"
          first-number
          last-number
        />
      </b-col>
      <b-col cols="4" class="text-right">
        <b-form-select
          style="width: 5em"
          v-model="perPage"
          id="perPageSelect"
          :options="pageOptions"
        ></b-form-select>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import { mapState, mapGetters } from "vuex";

export default {
  props: {
    qr: {
      type: Number,
      default: -1,
    },
  },
  computed: {
    ...mapState(["account", "events"]),
    ...mapGetters(["admin/allow_this_tab", "admin/allow_this_action"]),
    truncateUrl() {
      const width = this.events.width;
      if (width <= 992) {
        return 50;
      } else if (width <= 1200) {
        return 20;
      } else if (width <= 1600) {
        return parseInt(width / 30);
      } else {
        return parseInt(width / 15);
      }
    },
  },
  data() {
    return {
      fields: [
        {
          key: "access_date",
          label: "Accés",
          sortable: true,
          sortDirection: "desc",
        },
        {
          key: "city",
          label: "GeoLocation",
          sortable: false,
          sortDirection: "asc",
        },

        {
          key: "user_agent",
          label: "User agent",
          sortable: true,
          sortDirection: "desc",
          class: "text-truncate",
        },
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [1, 5, 10],
      sortBy: "access_date",
      sortDesc: true,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      isBusy: false,
    };
  },
  created() {},
  mounted() {
    this.currentPage = 1;
  },
  beforeDestroy() {
    this.items = this.fields = this.pageOptions = undefined;
  },
  methods: {
    async myProvider(ctx) {
      this.isBusy = true;
      if (ctx && ctx.filter && ctx.filter.length > 30) {
        this.totalRows = 0;
        this.isBusy = false;
        return [];
      }

      this.$store.dispatch("axios/http_reading", true);

      const vm = this.$root;
      await vm.handleGetRequest("api/token/get", false);
      const data = await vm.handleGetRequest("qr/codes/history", true, {
        qr: this.qr,
        currentPage: ctx.currentPage,
        perPage: ctx.perPage,
        filter: ctx.filter,
        sortBy: ctx.sortBy,
        sortDesc: ctx.sortDesc,
      });

      const tbl =
        data && data["qr_codes_history"] ? data["qr_codes_history"] : null;

      this.currentPage =
        tbl && tbl.currentPage ? tbl.currentPage : this.currentPage;
      this.totalRows = tbl && tbl.totalRows ? tbl.totalRows : 0;

      if (tbl && tbl.items) {
        this.isBusy = false;
        return JSON.parse(tbl.items);
      } else {
        this.currentPage = 1;
        this.isBusy = false;
        return [];
      }
    },
  },
};
</script>

<style scoped>
.table_color_light {
  background: red;
}
</style>